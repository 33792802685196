import React from 'react';
import './Fireworks.css';

const Fireworks: React.FC = () => {
  return (
    <div className='firework_main'>
      <h1>HAPPY NEW YEAR</h1>
      <div className="fireworks">
        <div className="firework">
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
        </div>
        <div className="firework" style={{ marginTop: '-70px' }}>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
        </div>
        <div className="firework">
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
        </div>
        <div className="firework" style={{ marginTop: '-70px' }}>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
        </div>
        <div className="firework">
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
        </div>
        <div className="firework" style={{ marginTop: '-70px' }}>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
          <div className="explosion">
            <div className="spark silver"></div>
          </div>
        </div>
        <div className="firework">
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
          <div className="explosion">
            <div className="spark gold"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fireworks;
